import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Documents, Invoices, Pagination } from '@one/types'
import qs from 'qs'

export class BackofficeInvoicesApi extends ApiServiceWithSettings {
  getInvoices({
    pageNumber,
    pageSize,
    search,
    paymentFilter,
    sortingMode,
    operatorName,
    clientId,
    startDate,
    endDate
  }: {
    pageNumber: number
    pageSize: number
    search: string | null
    paymentFilter: Invoices.InvoiceFilterEnum
    sortingMode: Invoices.SortingModeEnum
    operatorName?: string
    clientId?: string
    startDate?: string
    endDate?: string
  }): AxiosPromise<Pagination.Pager<Invoices.InvoicePagination>> {
    return this.get('/invoices', {
      params: {
        pageNumber,
        pageSize,
        search: search || null,
        clientId,
        paymentFilter: paymentFilter || 'ALL',
        sortingMode: sortingMode || 'NEWEST_TO_OLDEST',
        operatorName,
        startDate,
        endDate
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getSingleInvoice(invoiceNumber: string): AxiosPromise<Invoices.Invoice> {
    return this.get(`/invoices/single?number=${invoiceNumber}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getInvoicesPaymentData(
    document: Array<string>,
    clientId: string | null
  ): AxiosPromise<Documents.DocumentSums> {
    return this.get(`/invoices/sums`, {
      params: {
        document,
        clientId: clientId || undefined
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getInvoicesCorrectionsPaymentData(
    document: Array<string>,
    clientId: string | null
  ): AxiosPromise<Invoices.InvoiceSums> {
    return this.get(`/invoice-corrections/sums`, {
      params: {
        document,
        clientId: clientId || undefined
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getReceiptsPaymentData(
    document: Array<string>,
    clientId: string | null
  ): AxiosPromise<Invoices.InvoiceSums> {
    return this.get(`/fiscal-receipts/sums`, {
      params: {
        document,
        clientId: clientId || undefined
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getReceiptsCorrectionsPaymentData(
    document: Array<string>,
    clientId: string | null
  ): AxiosPromise<Invoices.InvoiceSums> {
    return this.get(`/fiscal-receipt-corrections/sums`, {
      params: {
        document,
        clientId: clientId || undefined
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getSuggestDocumentsList(
    pageNumber: number,
    pageSize: number,
    query: string | null,
    clientId: string | null
  ): AxiosPromise<Documents.DocumentsPagination> {
    return this.get('/accounting-documents/suggest', {
      params: {
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 10,
        query: query || null,
        clientId: clientId || null
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAllNumbersInvoices({
    search,
    paymentFilter,
    clientId,
    startDate,
    endDate,
    operatorName
  }: {
    search: string | null
    paymentFilter: Invoices.InvoiceFilterEnum
    clientId?: string
    startDate?: string
    endDate?: string
    operatorName?: string
  }): AxiosPromise<Documents.DocumentSums> {
    return this.get(`/invoices/sums`, {
      params: {
        clientId,
        search: search || null,
        paymentFilter: paymentFilter || 'ALL',
        startDate,
        endDate,
        operatorName
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAllNumbersInvoicesCorrections({
    search,
    paymentFilter,
    clientId,
    startDate,
    endDate,
    operatorName
  }: {
    search: string | null
    paymentFilter: Invoices.InvoiceFilterEnum
    clientId?: string
    startDate?: string
    endDate?: string
    operatorName?: string
  }): AxiosPromise<Invoices.InvoiceNumbers> {
    return this.get(`/invoice-corrections/sums`, {
      params: {
        clientId,
        search: search || null,
        paymentFilter: paymentFilter || 'ALL',
        startDate,
        endDate,
        operatorName
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAllNumbersReceipts({
    search,
    paymentFilter,
    clientId,
    startDate,
    endDate,
    operatorName
  }: {
    search: string | null
    paymentFilter: Invoices.InvoiceFilterEnum
    clientId?: string
    startDate?: string
    endDate?: string
    operatorName?: string
  }): AxiosPromise<Invoices.InvoiceNumbers> {
    return this.get(`/fiscal-receipts/sums`, {
      params: {
        clientId,
        search: search || null,
        paymentFilter: paymentFilter || 'ALL',
        startDate,
        endDate,
        operatorName
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAllNumbersReceiptsCorrections({
    search,
    paymentFilter,
    clientId,
    startDate,
    endDate,
    operatorName
  }: {
    search: string | null
    paymentFilter: Invoices.InvoiceFilterEnum
    clientId?: string
    startDate?: string
    endDate?: string
    operatorName?: string
  }): AxiosPromise<Invoices.InvoiceNumbers> {
    return this.get(`/fiscal-receipt-corrections/sums`, {
      params: {
        clientId,
        search: search || null,
        paymentFilter: paymentFilter || 'ALL',
        startDate,
        endDate,
        operatorName
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  exportInvoice(number: string): AxiosPromise<BlobPart> {
    return this.get('/invoices/export', {
      params: {
        number,
        'one-tenant': this.getTenantKey()
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      responseType: 'blob'
    })
  }

  exportInvoiceCorrections(number: string): AxiosPromise<BlobPart> {
    return this.get('/invoice-corrections/export', {
      params: {
        number,
        'one-tenant': this.getTenantKey()
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      responseType: 'blob'
    })
  }

  exportFiscalReceipts(number: string): AxiosPromise<BlobPart> {
    return this.get('/fiscal-receipts/export', {
      params: {
        number,
        'one-tenant': this.getTenantKey()
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      responseType: 'blob'
    })
  }

  exportFiscalReceiptCorrections(number: string): AxiosPromise<BlobPart> {
    return this.get('/fiscal-receipt-corrections/export', {
      params: {
        number,
        'one-tenant': this.getTenantKey()
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      responseType: 'blob'
    })
  }

  getSumsInvoices(
    pageNumber: number,
    pageSize: number,
    clientId?: string,
    endDate?: string,
    invoiceFilter?: Invoices.InvoiceFilterEnum,
    invoiceTypeFilter?: Invoices.InvoiceTypeEnum,
    startDate?: string
  ): AxiosPromise<Pagination.Pager<Invoices.Backoffice.InvoicesAmount>> {
    return this.get('/invoice/amounts', {
      params: {
        pageNumber,
        pageSize,
        clientId,
        invoiceFilter: invoiceFilter || 'ALL',
        invoiceTypeFilter: invoiceTypeFilter || 'ALL',
        endDate,
        startDate
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getInvoiceCorrections({
    pageNumber,
    pageSize,
    search,
    paymentFilter,
    sortingMode,
    operatorName,
    clientId,
    startDate,
    endDate
  }: {
    pageNumber: number
    pageSize: number
    search: string | null
    paymentFilter: Invoices.InvoiceFilterEnum
    sortingMode: Invoices.SortingModeEnum
    operatorName?: string
    clientId?: string
    startDate?: string
    endDate?: string
  }): AxiosPromise<Pagination.Pager<Invoices.InvoicePagination>> {
    return this.get('/invoice-corrections', {
      params: {
        pageNumber,
        pageSize,
        search: search || null,
        clientId,
        paymentFilter: paymentFilter || 'ALL',
        sortingMode: sortingMode || 'NEWEST_TO_OLDEST',
        operatorName,
        startDate,
        endDate
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getSingleInvoiceCorrection(
    correctionNumber: string
  ): AxiosPromise<Invoices.Invoice> {
    return this.get(`/invoice-corrections/single?number=${correctionNumber}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createInvoiceCorrection(payload: any): AxiosPromise<any> {
    return this.post('/invoice-corrections', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getFiscalReceipts({
    pageNumber,
    pageSize,
    search,
    paymentFilter,
    sortingMode,
    operatorName,
    clientId,
    startDate,
    endDate
  }: {
    pageNumber: number
    pageSize: number
    search: string | null
    paymentFilter: Invoices.InvoiceFilterEnum
    sortingMode: Invoices.SortingModeEnum
    operatorName?: string
    clientId?: string
    startDate?: string
    endDate?: string
  }): AxiosPromise<Pagination.Pager<Invoices.InvoicePagination>> {
    return this.get('/fiscal-receipts', {
      params: {
        pageNumber,
        pageSize,
        search: search || null,
        clientId,
        paymentFilter: paymentFilter || 'ALL',
        sortingMode: sortingMode || 'NEWEST_TO_OLDEST',
        operatorName,
        startDate,
        endDate
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getSingleFiscalReceipts(
    fiscalReceiptNumber: string
  ): AxiosPromise<Invoices.Invoice> {
    return this.get(`/fiscal-receipts/single?number=${fiscalReceiptNumber}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createFiscalReceipt(payload: any) {
    return this.post('/fiscal-receipts', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getFiscalReceiptCorrections({
    pageNumber,
    pageSize,
    search,
    paymentFilter,
    sortingMode,
    operatorName,
    clientId,
    startDate,
    endDate
  }: {
    pageNumber: number
    pageSize: number
    search: string | null
    paymentFilter: Invoices.InvoiceFilterEnum
    sortingMode: Invoices.SortingModeEnum
    operatorName?: string
    clientId?: string
    startDate?: string
    endDate?: string
  }): AxiosPromise<Pagination.Pager<Invoices.InvoicePagination>> {
    return this.get('/fiscal-receipt-corrections', {
      params: {
        pageNumber,
        pageSize,
        search: search || null,
        clientId,
        paymentFilter: paymentFilter || 'ALL',
        sortingMode: sortingMode || 'NEWEST_TO_OLDEST',
        operatorName,
        startDate,
        endDate
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getSingleFiscalReceiptCorrection(
    fiscalReceiptNumber: string
  ): AxiosPromise<Invoices.Invoice> {
    return this.get(
      `/fiscal-receipt-corrections/single?number=${fiscalReceiptNumber}`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  createFiscalReceiptCorrection(payload: any) {
    return this.post('/fiscal-receipt-corrections', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
