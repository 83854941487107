import { ApiService, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Pricing, Products } from '@one/types'
import ProductsPrices = Products.Requests.ProductsPrices
import ProductPrice = Products.ProductPrice
import PromoPointRestView = Pricing.PromoPointRestView

export class AppPricingApi extends ApiService {
  fetchPricingForProducts(
    products: ProductsPrices
  ): AxiosPromise<Array<ProductPrice>> {
    return this.post('/get-price', products, {
      authentication: Authentication.OPTIONAL,
      cache: {
        ttl: 5000,
        tags: ['prices', 'products', 'product']
      }
    })
  }

  getAdditionalCostTypeName(
    additionalCostTypeId: string
  ): AxiosPromise<Pricing.SimpleAdditionalCostTypeDto> {
    return this.get(`/additional-costs/types/${additionalCostTypeId}`, {
      authentication: Authentication.PUBLIC,
      cache: {
        tags: ['additional-cost-types']
      }
    })
  }

  getPromoPoints(): AxiosPromise<Array<PromoPointRestView>> {
    return this.get(`/promo-points`, {
      authentication: Authentication.REQUIRED
    })
  }
}
